.Dashboard {
  display: flex;
  justify-content: space-between;
  background: #fdfffc;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.LeftMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 75px;
  height: calc(100vh + 10px);
  border-right: 1px solid rgb(231, 231, 231);
}

.menu {
  margin-top: 40px;
  cursor: pointer;
}

.Constructor {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.RightMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-left: 1px solid rgb(231, 231, 231);
  width: 75px;
  padding: 5px;
  min-height: 100vh;
}

.SubHeader {
  display: flex;
  align-items: center;
  text-align: start;
  margin: 10px 40px;
  height: max-content;
}

.Header {
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  margin: 10px 40px;
  height: max-content;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.Header span {
  font-size: 4vmin;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-top: -20px;
}

.Header p {
  cursor: pointer;
  width: max-content;
  font-size: 1.5vmin;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-top: -20px;
  text-decoration: overline;
  margin-left: 5px;
}

.green {
  color: #55ad9c;
}

.yellow {
  color: #f6be00;
}

.HelperInfo {
  margin-top: 40px;
  font-size: 16px;
}

.Body img {
  height: 90px;
}

.cost {
  display: none;
  color: #3fbba2;
  margin-left: 7px;
  padding: 0;
  left: 0;
  font-weight: bold;
  right: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 16px;
  font-style: italic;
}

.Header img {
  height: 12vmin;
  width: 12vmin;
}

.Body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 10px 30px;
}

.ParamItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
}

.ParamItem img {
  width: 250px;
}

.ItemOptions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.CheckOption {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.EmptyList {
  width: 100%;
  text-align: start;
  font-style: italic;
}

.FieldOption {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}

.ItemTitle {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.JobList {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  max-height: 500px;
  overflow: scroll;
  margin-top: 10px;
}

.JobRow {
  width: calc(100% - 90px);
  display: flex;
  justify-content: center;
}

.SubJobRow {
  width: max-content;
  display: flex;
  padding: 10px;
  align-items: center;
  margin-top: 10px;
  border: #f6be00 1px solid;
}

.JobUrl-w {
  width: 200px;
  padding: 5px;
  height: 30px;
  border: none;
  font-size: 16px;
}

.JobTime-w {
  margin-left: 10px;
  margin-right: 10px;
}

.JobUrl-d {
  width: 200px;
  padding: 5px;
  height: 30px;
  border: none;
  color: #fff;
  font-size: 16px;
  background: #1b1f23;
}

.JobTime-d {
  margin-left: 10px;
  margin-right: 10px;
}

.field {
  border-bottom: #1b1f23 2px solid;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-left: 20px;
  background: none;
  width: 300px;
  font-size: 16px;
}

.Footer {
  width: calc(100% - 30px);
  display: flex;
  justify-content: center;
  padding: 15px;
}

.HeaderButtons {
  display: flex;
}

.Button {
  border: 2px solid #1b1f23;
  border-radius: 3px;
  padding: 10px 20px;
  font-weight: bold;
  background: #fff;
  text-decoration: none;
  margin: 8px;
  cursor: pointer;
  width: max-content;
}

.Button:hover {
  color: #fff;
  background: #1b1f23;
}

.Theme {
  border: #1b1f23 3px solid;
  border-radius: 5px;
  width: max-content;
  margin: 20px auto;
  cursor: pointer;
}

.Sun {
  padding: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.Moon {
  padding: 5px;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
}

.dark {
  background: #1b1f23;
  color: #fff;
}

.white {
  background: #fff;
  color: #1b1f23;
}

.Theme.white {
  border: #fff 3px solid;
}

.Button.dark {
  border: #fff 3px solid;
}

.field.dark {
  color: #fff;
  border-bottom: #fff 3px solid;
}

.Button.dark:hover {
  background: #fff;
  color: #1b1f23;
}

.Footer.dark {
  background: #262a2d;
}

.Footer.white {
  background: #ecf2f2;
}

.JobCard.white {
  height: 200px;
  width: 500px;
  margin: auto;
  background: #fff;
  border: #fff 1px solid;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}

.JobCard.dark {
  height: 200px;
  width: 500px;
  margin: auto;
  background: #1b1f23;
  border: #1b1f23 1px solid;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}

.CardBackground {
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  position: absolute;
  background: #aa9e7e38;
}

.CardHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.CardHeader svg {
  padding: 10px;
  cursor: pointer;
}

.inputs {
  display: flex;
  flex-direction: column;
  width: 320px;
}

.timer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.CardHeader span {
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.ColumnHeader {
  display: flex;
  margin-top: 10px;
}

.ColumnHeader select {
  padding: 0 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #1b1f23;
  font-weight: bold;
  font-size: 15px;
  margin-left: 10px;
}

.ColumnHeader select.dark {
  border-bottom: 3px solid #fff;
}

.ColumnHeader .field {
  font-size: 15px;
  font-weight: bold;
  padding-left: 20px;
  width: 110px;
}

.ColumnHeader .Button {
  padding: 5px 7px;
  margin-left: 20px;
  margin-bottom: -0px;
}

.ColumnList {
  background: #262a2d11;
  height: 100%;
  margin: 20px;
  overflow-y: scroll;
}

.ColumnList.dark {
  background: #f1f1f125;
  height: 100%;
  margin: 20px;
}

.ColumnBottom {
  align-self: flex-end;
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: auto;
  margin-right: 10px;
}

.ColumnBottom .Button {
  width: max-content;
}

.columnIssue {
  margin-top: 10px;
  padding: 6px 10px;
  width: max-content;
  margin-left: 15px;
  border-radius: 4px;
  background: rgba(255, 247, 0, 0.733);
}

.columnRow {
  display: flex;
  padding: 10px;
  align-items: center;
  background: #53a26c3c;
  margin: 5px;
  flex-direction: row;
}

.columnRow .Button {
  font-size: 10px;
  padding: 5px 10px;
}

.columnRow span {
  margin-right: 10px;
  min-width: 100px;
}

.ColumnBottom .field {
  margin-bottom: 8px;
  padding: 10px;
  margin-right: 10px;
  font-size: 20px;
}

.tip {
  width: 100px;
  background: #55ad9bc4;
  color: #fff;
  padding: 5px;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 12px;
}

.ListOfJobs {
  overflow-y: scroll;
  max-height: 450px;
}

.Loader-dark {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 8vmax;
  height: 8vmax;
  border-right: 6px solid rgb(255, 255, 255);
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;
}

.Loader-white {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 8vmax;
  height: 8vmax;
  border-right: 6px solid rgb(0, 0, 0);
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;
}

@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
