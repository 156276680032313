.Landing {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.Menu {
    position: fixed;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    padding-bottom: 20px;
}

.MenuItem {
   margin-right: 3vmin;
    margin-left: 3vmin;
    font-size: 20px;
    font-weight: bold;
    color: #000;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.MenuItem:hover {
    border-bottom: 2px solid rgb(246, 190, 0);
}

.Header {
    display: flex;
    padding: 2vmin;
    border-bottom: none;
    margin-top: 10vmin;
}

.Title {
    width: 50vmin;
}

.Time {
    min-width: 70vmin;
    min-height: 70vmin;
}

.HeaderContent {
    display: flex;
    flex-direction: column;
}

.Button-Get-Started {
    margin-top: 5vmin;
    width: 50vmin;
    height: 10vmin;
    font-size: 3vmin;
    font-weight: bold;
    background-color: rgb(246, 190, 0);
    border: none;
    border-radius: 5vmin;
    color: white;
    cursor: pointer;
}

.Title {
    font-size: 10vmin;
}

.Subtitle {
    font-size: 4vmin;
}

.About {
    margin-top: 20vmin;
    text-align: start;
    padding: 10vmin 10vmin;
    background: -webkit-linear-gradient(#000, rgb(246, 190, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* background: rgb(246, 190, 0);
    color: #fff; */
}

.AboutTitle {
    font-size: 10vmin;
    font-weight: bold;
    margin-top: 5vmin;
    color: #000;
    margin-bottom: 5vmin;
}

.AboutText {
    font-size: 3vmin;
    margin-top: 2vmin;
    text-align: start;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.Prices {
    margin-top: 20vmin;
    text-align: start;
    padding: 10vmin 10vmin;
    background: -webkit-linear-gradient(#000, rgb(246, 190, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.PriceContent {
    display: flex;
}

.PricesTitle {
    font-size: 10vmin;
    font-weight: bold;
    margin-top: 5vmin;
    color: #000;
    margin-bottom: 5vmin;
}

.PricesText {
    font-size: 3vmin;
    margin-top: 2vmin;
    text-align: start;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: 50vmin;
    margin-right: 10vmin;
}

.MoneyIcon {
    margin-top: -10vmin;
    width: 45vmin;
    height: 45vmin;
}

.Feedbacks {
    margin-top: 20vmin;
    text-align: start;
    padding: 10vmin 10vmin;
    background: -webkit-linear-gradient(#000, rgb(246, 190, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.FeedbacksTitle {
    font-size: 10vmin;
    font-weight: bold;
    margin-top: 5vmin;
    color: #000;
    margin-bottom: 10vmin;
}

.FeedbacksContent {
    display: flex;
    flex-direction: column;
}

.FeedbackItem {
    display: flex;
    align-items: center;
    margin-bottom: 10vmin;
}

.CommentImg {
    width: 10vmin;
    height: 10vmin;
    border-radius: 50%;
    margin-right: 3vmin;
}

.Comment {
    display: flex;
    flex-direction: column;
}

.CommentOwner {
    font-size: 5vmin;
}

.CommentText {
    max-width: 120vmin;
    flex-wrap: wrap;
}

.Footer {
    display: flex;
    flex-direction: column;
    background: rgb(246, 190, 0);
    padding: 3vmin;
    color: #fff;
    font-size: 2vmin;
    align-items: center;
}

.FooterTitle {
    font-weight: bold;
}

.FooterText {
    font-weight: bold;
}

.Company {
    margin-top: 1vmin;
    font-style: italic;
}

.Links {
    margin-top: 1vmin;
    display: flex;
}

.Links a {
    margin-right: 1vmin;
    margin-left: 1vmin;
}